import React from 'react';
import { Helmet } from 'react-helmet';
import SEO from '../../components/seo/seo';

/**
 * About Page
 */
export default function AboutPage() {
  /**
   * Decode encode email address
   * @param {string} encodedString
   * If need to encode another address, see encodeEmail() at the bottom of this file.
   * From: https://andrewlock.net/simple-obfuscation-of-email-addresses-using-javascript/.
   */
  function decodeEmail(encodedString) {
    // Holds the final output
    let email = '';

    // Extract the first 2 letters
    let keyInHex = encodedString.substr(0, 2);

    // Convert the hex-encoded key into decimal
    let key = parseInt(keyInHex, 16);

    // Loop through the remaining encoded characters in steps of 2
    for (let n = 2; n < encodedString.length; n += 2) {
      // Get the next pair of characters
      let charInHex = encodedString.substr(n, 2);

      // Convert hex to decimal
      let char = parseInt(charInHex, 16);

      // XOR the character with the key to get the original character
      let output = char ^ key;

      // Append the decoded character to the output
      email += String.fromCharCode(output);
    }
    return email;
  }

  // Unencode hello@devshows.dev
  const emailEncoded = 'c7afa2ababa887a3a2b1b4afa8b0b4e9a3a2b1';
  let emailUnencoded = '';
  if (typeof window !== 'undefined') {
    emailUnencoded = decodeEmail(emailEncoded);
  }

  // If leverage value from config, could add gql query and do this:
  // {typeof window !== 'undefined' && decodeEmail(site.siteMetadata.email)}

  // THIS WORKS BUT MORE COMPLICATED THAN NECESSARY
  // , { useState, useEffect }
  // Default is encoded version of hello@devshows.dev
  // const [email, setEmail] = useState('');

  // const useEffectOnlyOnce = (func) => useEffect(func, []);
  // useEffectOnlyOnce(() => {
  //   setEmail(decodeEmail('c7afa2ababa887a3a2b1b4afa8b0b4e9a3a2b1'));
  // });

  return (
    <>
      <SEO title="About Dev Shows." />
      <Helmet>
        <body className="theme-1 page-about" />
      </Helmet>
      <div className="about wrapMedium">
        <h1 className="headingPage">About Dev Shows</h1>

        <p>
          I realize this almost certainly puts me in the minority, but I listen
          to podcasts in a browser rather than an app. It’s one fewer thing to
          manage, and I have a browser open all the time anyway. Plus I don’t
          listen to podcasts while on the go.
        </p>
        <p>
          Having accumulated a list of tech podcasts I’d frequent,{' '}
          <cite>Dev Shows</cite> seemed like the perfect excuse to build
          something fun while learning a bit along the way. And maybe someone
          else would also find it useful, even if only as a source for
          discovering a show before listening to it in their preferred app.
        </p>

        {/* ==== Featured shows ==== */}
        <h2>Featured shows</h2>
        <p>
          Currently, I don’t aim to make <cite>Dev Shows</cite> a comprehensive
          source of every software and web development podcast that is—or once
          was—active. That type of resource does exist for those who are
          interested, but I personally find they can be hard to weed through.
        </p>

        <p>Instead, I wanted something that:</p>
        <ul>
          <li>
            regularly has fresh, interesting content (that’s subjective,
            obviously);
          </li>
          <li>
            skews toward, but isn’t solely about, the front end and Jamstack;
          </li>
          <li>and doesn’t contain a lot of old, discontinued shows.</li>
        </ul>

        <p>
          This may change over time—especially if you all want to see the site
          expand its breadth—but it’s my current thinking anyway.
        </p>

        {/* ==== Suggesting a show ==== */}
        <h2>Think a good show is missing?</h2>
        <p>
          I’ve searched many corners of the web for shows, but I don’t doubt
          I’ve missed some that would be a good fit.
        </p>
        <p>
          If <strong>one of your favorite shows is missing</strong>, please feel
          free to{' '}
          <a href={`mailto:${emailUnencoded}`}>
            <strong>suggest a show</strong>
          </a>
          . Before doing so, however, please be sure it has a track record of
          publishing episodes for at least a recent stretch; in short, that it’s
          shown it intends to stick around. I greatly respect and appreciate
          anyone who starts a tech podcast, but I also know it can be hard to
          keep it going because of other demands on ones time.
        </p>

        <p>
          I can’t promise I’ll add every suggested show, so please be kind.
          Also, I have a list of shows I’m considering, so it’s possible your
          suggestion is already on it. (It takes a bit of time to add one to the
          site.)
        </p>

        {/* ==== Trouble with audio ==== */}
        <h2>Having trouble with audio playback?</h2>
        <p>
          If an episode isn’t playing, it may be due to an extension you have
          installed in your browser. For instance, I’ve noticed that{' '}
          <a href="https://privacybadger.org/" rel="external">
            Privacy Badger
          </a>{' '}
          blocks MP3s hosted on Simplecast, and that{' '}
          <a href="https://github.com/gorhill/uBlock/" rel="external">
            uBlock Origin
          </a>{' '}
          may block some others. In either case, it could result in an error
          like this showing in the browser console:
        </p>

        <blockquote>
          <samp>
            NotSupportedError: The media resource indicated by the src attribute
            or assigned media provider object was not suitable.
          </samp>
        </blockquote>

        <p>
          If you run into this, please try disabling your extension for{' '}
          <cite>Dev Shows</cite>, and if the trouble persists, please check any
          restrictive browser settings you may have set. If neither does the
          trick, feel free to{' '}
          <a href={`mailto:${emailUnencoded}`}>get in touch</a>, and I’ll try to
          figure out the source of the issue.
        </p>

        {/* ==== Analytics ==== */}
        <h2 id="analytics">Analytics</h2>
        <p>
          <cite>Dev Shows</cite> uses{' '}
          <a href="https://plausible.io/" rel="external">
            Plausible Analytics
          </a>
          , an{' '}
          <a href="https://github.com/plausible/analytics" rel="external">
            open-source solution
          </a>{' '}
          that allows me to gather basic usage information (such as browsers,
          OSes, the amount of traffic, and the like) with a light touch.
          Specifically, Plausible doesn’t set any cookies and is
          privacy-conscious. Here’s an explanation of {' '}
          <a href="https://plausible.io/data-policy" rel="external">
            what data they collect and how they handle it
          </a>
          .
        </p>

        {/* ==== Tech stack ==== */}
        <h2>Tech stack</h2>
        <p>
          For those who are interested, here’s a quick rundown of what I used to
          build <cite>Dev Shows</cite>:
        </p>
        <ul>
          <li>
            <a href="https://github.com/features/actions" rel="external">
              GitHub Actions
            </a>{' '}
            to automatically run the data collection multiple times a day and
            fire off the build process when at least one new episode comes
            through;
          </li>
          <li>
            <a href="https://nodejs.org/" rel="external">
              Node.js
            </a>{' '}
            for collecting the RSS data for each show;
          </li>
          <li>
            <a href="https://www.gatsbyjs.org/" rel="external">
              Gatsby
            </a>{' '}
            (I wavered on this some) for the site itself;
          </li>
          <li>
            Plausible Analytics (<a href="#analytics">as explained above</a>);
          </li>
          <li>
            and the mighty{' '}
            <a href="https://www.netlify.com/" rel="external">
              Netlify
            </a>{' '}
            for hosting.
          </li>
        </ul>

        {/* ==== Thanks ==== */}
        <h2>Thanks for stopping by</h2>
        <p>Happy listening!</p>
        <p>
          —Bruce
          <br />
          <i>July 2020</i>
        </p>

        <p>
          p.s. I also have ideas for features and other things I’m considering
          adding as time allows. Please stay tuned.
        </p>
      </div>
    </>
  );
}

/*
<li>
<a href="https://www.gatsbyjs.com/" rel="external">
  Gatsby Builds on Gatsby Cloud
</a>{' '}
to build the site;
</li>
*/

/*
If you have any suggestions, please feel free to <a href={`mailto:${emailUnencoded}`}><strong>reach out</strong></a>. I can’t promise I’ll add them, but I welcome the input and feedback. Thanks!
*/

// I also thought someone “out there” might find it useful, even if only as a source for discovering a new (to them) show before listening to it in their preferred app.

// <p>
// Really, it just comes down to one person’s opinion, so please don’t
// take it anything more than that if your favorite—or your own—show is
// missing. I don’t have some rigid scoring system or a review panel.
// </p>

// <p>
// Regularity:the show has published several episodes and at a decent
// pace. I don’t want to fill the site with shows that are shut down
// after just a few episodes or that publish sporadically.
// </p>

/**
 * Encode an email address to it's harder for bots to scrape.
 * @param {string} email The email address to encode.
 * @param {number} key Any 0-255 number
 * Function not needed in the site but am putting it here in case Andrew Lock's page 404s and I need to encode another email in the future.
 */
// function encodeEmail(email, key) {
//   // Hex encode the key
//   var encodedString = key.toString(16);

//   // loop through every character in the email
//   for(var n=0; n < email.length; n++) {

//       // Get the code (in decimal) for the nth character
//       var charCode = email.charCodeAt(n);

//       // XOR the character with the key
//       var encoded = charCode ^ key;

//       // Hex encode the result, and append to the output string
//       encodedString += encoded.toString(16);
//   }
//   return encodedString;
// }
